import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

import utkarshlogo from '../../../assets/utkarsh-logo.png';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    // <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <div component={Link} to="/">
        {/* <Logo /> */}
        <img src={utkarshlogo} alt="utkarsh logo" style={{ display: 'block' }} />
    </div>
);

export default LogoSection;
